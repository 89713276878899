<template>
    <div>

        <div class="contenedor">

            <rutaPagina :ruta="ruta" />
                        
            <div class="row glass panel"  >

                <div class="header-titulo">
                    <div class="titulo">
                        <tituloPagina texto="Detalle orden de compra" />
                        <span class="numero-orden">Número de orden : {{id_orden_compra}} </span>
                    </div>
                    <span class="estado-abierto" >Abierto</span>    
                </div>
                
                <div class="contenedor-tabla">
                    <div class="table-responsive">
                        <table id="tblDetalleOrdenCompra" class="tabla-ng">
                            <thead>
                                <tr class="omitir-bordes">
                                    <th scope="col" >Código</th>
                                    <th scope="col" >Descripción</th>
                                    <th scope="col" >Medida</th>
                                    <th scope="col" >Cant. ordenada</th>
                                    <th scope="col" >Cant. pendiente</th>
                                    <th scope="col" >Precio</th>
                                    <th scope="col" >Descuento</th>
                                    <th scope="col" >Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="omitir-bordes">
                                    <td>PRV-0070</td>
                                    <td>SOLFAC EC 050 BOTELLA x LT</td>
                                    <td>UND</td>
                                    <td>200.0</td>
                                    <td>0.0</td>
                                    <td>$ 197.000.000</td>
                                    <td>$ 0.0</td>
                                    <td>$ 197.000.000</td>
                                </tr>  
                                <tr class="omitir-bordes">
                                    <td>PRV-0070</td>
                                    <td>SOLFAC EC 050 BOTELLA x LT</td>
                                    <td>UND</td>
                                    <td>200.0</td>
                                    <td>0.0</td>
                                    <td>$ 197.000.000</td>
                                    <td>$ 0.0</td>
                                    <td>$ 197.000.000</td>
                                </tr>  
                                <tr class="omitir-bordes">
                                    <td colspan="6"></td>
                                    <td> SUBTOTAL  </td>
                                    <td>$ 197.000.000</td>
                                </tr>  
                                <tr class="omitir-bordes">
                                    <td colspan="6"></td>
                                    <td>IVA</td>
                                    <td>$ 0.0</td>
                                </tr>     
                                <tr class="omitir-bordes">
                                    <td colspan="6"></td>
                                    <td><b>TOTAL</b></td>
                                    <td><b>$ 197.000.000</b></td>
                                </tr>        
                            </tbody>
                        </table>
                    </div>                    
                </div>

                <div class="titulo">
                    <tituloPagina texto="Anexos" />
                </div>

                <div class="contenedor-anexos">
                    <div class="row">
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div class="box-anexo">
                                <i class='bx bx-image-alt'></i>
                                <small>Nombre archivo</small>
                                <small>Tamaño : 24kb</small>
                                <small>Fecha : 02/11/2021</small>
                                <span>Descargar <i class='bx bxs-download' ></i></span>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div class="box-anexo">
                                <i class='bx bx-image-alt'></i>
                                <small>Nombre archivo</small>
                                <small>Tamaño : 24kb</small>
                                <small>Fecha : 02/11/2021</small>
                                <span>Descargar <i class='bx bxs-download' ></i></span>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div class="box-anexo">
                                <i class='bx bx-image-alt'></i>
                                <small>Nombre archivo</small>
                                <small>Tamaño : 24kb</small>
                                <small>Fecha : 02/11/2021</small>
                                <span>Descargar <i class='bx bxs-download' ></i></span>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div class="box-anexo">
                                <i class='bx bx-image-alt'></i>
                                <small>Nombre archivo</small>
                                <small>Tamaño : 24kb</small>
                                <small>Fecha : 02/11/2021</small>
                                <span>Descargar <i class='bx bxs-download' ></i></span>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div class="box-anexo">
                                <i class='bx bx-image-alt'></i>
                                <small>Nombre archivo</small>
                                <small>Tamaño : 24kb</small>
                                <small>Fecha : 02/11/2021</small>
                                <span>Descargar <i class='bx bxs-download' ></i></span>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div class="box-anexo">
                                <i class='bx bx-image-alt'></i>
                                <small>Nombre archivo</small>
                                <small>Tamaño : 24kb</small>
                                <small>Fecha : 02/11/2021</small>
                                <span>Descargar <i class='bx bxs-download' ></i></span>
                            </div>
                        </div> 
                                                                     
                    </div>
                </div>

            </div>
        </div>        

    </div>
</template>

<script>
import rutaPagina from '@/components/RutaPagina'
import tituloPagina from '@/components/Titulopagina'
import { useRoute } from 'vue-router'
import {  ref, onMounted,nextTick } from 'vue'

export default {
    name :'Detalle orden compra',
    setup(props) {
        const route = useRoute()
        const id_orden_compra = ref(0)    
        
        const ruta = [
            { nombre : 'Documentos'},
            { nombre : 'Orden de compra' , ruta: "/ordencompra"},
            { nombre : 'Detalle orden de compra'},
        ]

        onMounted(() => {
            id_orden_compra.value = route.params.detalle;            
        })

        return {
            id_orden_compra,ruta
        }
    },
    components : {
        tituloPagina,
        rutaPagina,
    }
}
</script>

<style scoped>
    .numero-orden{
        background-color: #b6b7bb4f;
        color: #000000;
        border-radius: 10px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.75rem;
        margin-top: -12px;
        margin-left: 17px;
        font-weight: bold;
    }
   
    
    .box-opcion-menu{
        width: 2rem;
        height: 2rem;
        background: var(--ng-primario);
        margin-left: 0.5rem;
        margin-top: 0.2rem;
        margin-bottom: 0.3rem;
        border-radius: 3px;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .box-opcion-menu i{
        color: #ffffff;
        font-size: 1.7rem;
    }    
    
</style>